.fromHead{
	font-size: 15px;
	font-weight: normal;
	padding-bottom: 5px; 
}
.customDatePicker{
	height: 35px !important;
    padding-left: 10px !important;
    border-radius: 3px !important;
    border: 1px solid #999 !important;
    width:200px !important;
}
.react-datepicker{
	font-size: 12px !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
		font-size: 12px !important;

}
.submitOffering{
	background-color: #3c8dbc;
	padding: 10px;
	color: #fff;
	cursor: pointer;
	text-align: center;
	border-radius: 3px;
}
.borderOfOuter{
  box-shadow: 0px 2px 8px #888888;
	height: 290px;
	padding-top:10px; 
}
.tableCustomOC>thead>tr>th{
background-color: #ccc !important;
padding: 20px;
color: #333 !important;
}
