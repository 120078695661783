.seperatevideo{
	margin: 15px;
}
.setTopMargin{
	margin-top: 10px;
	margin-bottom: 10px;
}
.setMb{
	margin-bottom: 10px;
}
.ml45{
	margin-left: 70px;
}
.setMarginRight{
	margin-right: 24px;
}
.setMarginLeft{
	margin-left: 25px;
}
.zeroMarginB{
	margin-bottom: 0px;
}
label .zeroMarginB{
	margin-bottom: 0px;
}
.mr20{
	margin-right: 31px !important;
}
.nppadding{
	padding: 0px 0px;
}

h3 .heading b{
	font-family: montserrat-regular;
}


td a .pl50{
	margin-left: 41px;
}
td .textAlign{
	text-align: center;
}
.td_title{
	width: 25%;
}
td a .pt30{
	padding-top: 30px;
}

.setMarginTop{
	margin-top: 15px;
}
.zeromargin{
	margin-top: -5px;
}

.pptZeromargin {
    margin-top: -11px;
    margin-right: -35px;
}

.unpinicon:before{
	font-size: 18px;
	cursor: pointer;
	color: #3c8dbc;
}

.pinicon:before{
	font-size: 18px;
	cursor: pointer;
	color: #000;
}