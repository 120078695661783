.commentBox {

height: 160px;

}

.formcontent {

height: 90px;

}
textarea.form-control {
    height: auto !important;
}
.commentBoxbtn{
	background-color: #ccc;
}
.userImgB{

	height: 75px;
	width: 75px;
	border-radius: 50%;

}
.graycolor{
	color:#777;
}