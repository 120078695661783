/* Create a custom checkbox */
.centreDetailContainer{
  position: relative;
  padding-top: 5px;
  align-content: center;
  cursor: pointer;
  font-size: 10px;
  user-select: none;
  text-align: center;
}
/* Hide the browser's default checkbox */
.centreDetailContainer input {
     position: relative;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    height: 17px;
    width: 17px;
    right: 4px;
    top: -2px;
}
.centreDetailCheck {
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
.radioCheck {
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
/* On mouse-over, add a grey background color */
.centreDetailContainer:hover input ~ .centreDetailCheck{
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.centreDetailContainer input:checked ~ .centreDetailCheck {
  background-color: #3c8dbc;
  border:2px solid #3c8dbc;
}
/* Create the checkmark/indicator (hidden when not checked) */
.centreDetailCheck:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.centreDetailContainer input:checked ~ .centreDetailCheck:after {
  display: block;
}
/* Style the checkmark/indicator */
.centreDetailContainer .centreDetailCheck:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  align-content: center;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
/* On mouse-over, add a grey background color */
.centreDetailContainer:hover input ~ .radioCheck{
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.centreDetailContainer input:checked ~ .radioCheck {
  background-color: #3c8dbc;
  border:2px solid #3c8dbc;
}
/* Create the checkmark/indicator (hidden when not checked) */
.radioCheck:after {
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.centreDetailContainer input:checked ~ .radioCheck:after {
  display: block;
  background-color: transparent;
}
/* Style the checkmark/indicator */
.centreDetailContainer .radioCheck:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  align-content: center;
  border: solid white;
  border-width: 0 3px 3px 0;   


  transform: rotate(45deg);
}
.centreDetaillistItem{
  padding-left: 0px !important;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px !important;
}
.prodCheckboxDim{
  font-size: 28px;
}
.prodCheckboxDimSelected{
  color: #74c108;
  font-size: 28px;
  cursor: pointer;
}
.prodCheckboxDimNotSelected{
    color: #e8430f;
  cursor: pointer;

}