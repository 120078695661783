.userDetailsDiv label{
	font-size: 15px !important;
	font-weight: normal !important;
}
.userDetailsDiv{

	padding: 40px;
	padding-top:10px !important;

}
.userDetailsDiv1 label{
	font-size: 15px !important;
	font-weight: normal !important;
}
.userDetailsDiv1{

	padding: 40px;
	padding-top:10px !important;
	padding-bottom:10px !important;

}
.customStack li>a{
 border:1px solid #aaa;
}
.customStack>li.active>a, .customStack>li.active>a:focus, .customStack>li.active>a:hover{
	border-radius: 3px !important;
	background-color:#3c8dbc !important;
	color:#fff !important;
}
.customStack>li.active>a:hover{

}
.tableCustom>tbody>tr>td{
	padding: 15px;
	padding-top: 18px;
	padding-bottom: 18px;
}
.MasterBudgetTitle{
	font-size: 25px !important;
}
.backColorUserDetails{
	background-color: #3c8dbc;
	color: #fff;
}
.padding40{
	padding: 40px;
	padding-top: 10px !important;
}
.imagesDivUploaded{
	height: 80px;
	width: 27%;
	margin-top: 10px;
	margin-bottom: 40px;

}
.imagesDivUploaded img{
	height: 100%;
	width: 40%;
	margin-left: 10%;
}
.loadingImage{
	height: 120px;
	width: 30%;
	margin-top: 10px;
	margin-bottom: 40px;

}
.loadingImage img{
	height: 100%;
	width: 40%;
	margin-left: 10%;
}

.clr_k{
    
    height:80px !important;
    background-color: #f2f2f2;
    font-family:'Montserrat-Regular';
    cursor: pointer;
    border: 2px dotted #ccc;
}
.click_input{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin-left: -15px;
}
.hand_icon{
    margin-left: 40%;
    margin-top: 10px;
}
.padTopC{
    padding-top: 15px;
    padding-left: 0px;
}
.padLeftC{
    padding-left: 30px !important;
}
.noBold{
	padding-top:10px; 
	font-weight: normal !important;
}
.userInfoContainer{
	padding: 25px;
	box-shadow: 0px 2px 8px #888888;
}
/*custom radio button*/
.radioCheckIP{
  position: absolute;


  top: 2px;
  left: 45px;
  vertical-align: center !important;
  align-content: center;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
.centreDetailContainer input:checked ~ .radioCheckIP {
    background-color: #201a47;
    border: 2px solid #201a47;
    height: 15px;
    margin-top: 14px;
    width: 15px;
}
.centreDetailContainer input{
	    position: relative;
    opacity: 1;
    z-index: 1;
    cursor: pointer;
    height: 17px;
    width: 17px;
    right: 23px;
    top: 5px;
}
.centreDetaillistItemUS{
	margin-top: 13px;
	margin-left: -30px;

}
.addSpace{
	margin-left: 20px !important;
}

.customCircle{
	font-size:20px;
	color:red;
	cursor: pointer;
}
.pdfContainer{
    word-break: break-word;
}