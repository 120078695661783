.sidebar-header{
    color: #fff;
    padding-left: 20px;
}
.sidebartext{
	margin-left: 10px;
	  font-family:'Montserrat-Regular';
	  font-size: 13px;

}
.sideheader{
	font-size: 14px;
	font-family:'Montserrat-SemiBold';
	margin-left: 33px;
    margin-bottom: 20px;
}
