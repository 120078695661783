.RelatedBlogsBox{
	background-color: #fafafa;
	border: 1px solid #eaeaea;
	padding: 20px;
}
.mtop75{
	margin-top: 75px; 

}
.blogTitle{
	margin: 5px 0px;
	font-family: Arial;
}
.blogImgB{
	height: 173px!important;
	width: 100%;
}
.rblog{
	/*background-color: #888;*/
	
}
.r1blog1{
	height: 375px;
	margin-bottom: 50px;
	/*background-color: #aaa;*/
	box-shadow: 2px 2px 15px 4px #aaa;
}
.p10{
	padding-left:  25px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}