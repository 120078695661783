.padTop {
    padding-top: 15px;
}
.fa-trash{
	cursor: pointer
}
.td_description{
	width: 30%;
}
.td_pdf{
	width: 15%;
}
.td_reportimage{
	width: 15%;
}
.td_action{
	width: 15%;
}
.td_title{
	width: 25%;
}
.fa-trash, .fa-edit{
	font-size: 16px;
}
.reserachtable td, .reserachtable th{
	border: 1px solid #ccc;
}
.reportimageblock{
	padding-top: 21px;
}
.reportimgbox{
	width: 90%
}
.deletecross{
	position: absolute;
	left: 161px;
	cursor: pointer;
	top: -8px;
}