.blockquote{
	margin: 27px 0px 27px 0px;
    padding: 0px 0px 0px 27px;
    background-color: #f4f4f4;
    border-left: 5px solid #3c8dbc !important;

    /*border-color: #0cb4ce !important;*/
}


.blogBox p{
	font-family: 'Montserrat',sans-serif;
	text-align: justify !important;
	color: #444;
	margin-top: 40px;
}

.blogBoxSmall p>big>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBoxSmall p>strong>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBoxSmall p>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}

.blogBox p>big>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBox {
		overflow-wrap: break-word;

}
.blogBox p>a {
	color:#1055cc;
	text-decoration: underline;
}

.blogBoxSmall p{
	font-family: 'Montserrat',sans-serif;
	text-align: justify !important;
	color: #444;
	overflow-wrap: break-word;

}
.blogBoxSmall p>big>a{
	overflow-wrap: break-word;
}
.blogBoxSmall p>a{
	overflow-wrap: break-word;
}

.sCont{
	font-size: 14px;
}
.userbtn{
	padding: 8px 30px;
    width: 155px;
    margin-bottom: 25px;
    cursor: pointer;
	border: 1px solid #dddddd;
	background-color: #3c8dbc;
	columns: #111;
}
.userbtn:hover{
	background-color: #dddddd;
	color: #fff;
}
.blogDateSBP{
  font-size: 20px;
  font-weight: normal;
  font-family: 'Montserrat',sans-serif;
}