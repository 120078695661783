.footclr{
	color: #333;
    font-family:'Montserrat-Regular';
	/*z-index: 999;*/
}
.footerCSS{
	/*margin-top: 48%;*/
    /*z-index: 999;*/
}
.footerRightCss {
	color: #333;
    font-family:'Montserrat-SemiBold';
    font-size: 13px;
    margin-top: 5px;
}
.footerRightCss1 {
    color: #333;
    font-family:'Montserrat-Regular';
    font-size: 13px;
    margin-top: 5px;
}
.footerLeftCss a{
	font-family:'Montserrat-SemiBold';
    font-size: 13px;
}
.footerLeftCss{
	color: #333;
    font-family:'Montserrat-SemiBold';
    font-size: 13px;
    margin-top: 3px;
}