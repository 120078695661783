  .fixbox{
    height: 65px !important;
  }
  .outlinebox{
    outline: -webkit-focus-ring-color auto 0px!important;
  }
  .btnSubmit,.btnUpdate{
    color: #FFF;
    /*background-color: #303F9F !important;*/
    /*border-radius: 35px;*/
    margin-bottom: 30px;
    /*border-top-color: #303F9F;*/
    font-family:'Montserrat', sans-serif;
    letter-spacing: 1px;
    box-shadow: 2px 2px 2px #fff;

  }
  .tablefthr:hover{
    background: #2c1910 !important;
  }
  .companysettingboxs {
    /*border: 3px solid #d2d6de!important;*/
      margin-top: 1.75em;
      /*margin-left: -50px!important;*/
      min-height:  550px!important;
  }
  .nopadding {
    padding: 0 !important;
 
  }
 .input-group-addon{
      border: 0px solid #ccc !important;
  }
 .statelabel{
    font-weight: 300!important;
    font-size: 15px;
    font-weight: bold !important;
    font-family: 'Montserrat-Regular';
  }
  .formht{
    height: 75px;
  }
  .CStabl {
      padding-top:12px;
  }
  .settingbox{
      width:1000px!important;
      }
  .basicinfocmpset{
    /*border: 1px solid #d2d6de;*/
    height: 215px;
      margin-bottom: 15px;
  }
  .basicinfotxt{
      font-size: 14px !important;
      font-family:'Montserrat-Regular';
      font-weight: 700;
      color: #333;
      line-height: 1.1;
      margin-top: 5px;
      margin-bottom: 10px;
  }
  .Box-Bottom-Header{
  
    border-bottom: 1px solid #eee;
    margin-bottom: 3.5em;
  }
  
  .addcmtset{
    padding:0;
  }
  .contactcatcls{
      text-align: center;
      margin: 10px;
  }
  .tbltdfortr{
      width: 100%;
  
  }
  .rolelst{
      text-align: center;
     
  }
  .roletbl{
     text-align: center!important;
    
  }
  .rolelst1{
      width: 68%;
  }
  .actionth{
      text-align: left!important;
  }
  .roletble1{
     width: 5%;
  }
  .actiondata{
      width: 15%;
  }
  .rsimg{
      height: 50px;
      float: right;
  } 
  .mkpyinpt{
      margin-top: 80px;
  }
  .btnaligncentr{
      text-align: center;
  }
  .paybrder{
      border: 3px solid #d2d6de;
      margin-top: 25px;
      width: 85%;
      margin-left: 75px;
      padding-bottom: 25px;
  }
  
  .rolesField{
    border-radius: 3px!important;
  }
  .branchtd{
    width:100px!important;
  }
  .companysettingbox{
    min-height:385px!important;
      /*width:725px!important;*/
      /*margin-top: 1.75em;*/
  }
  .box-body{
      padding-top:30px!important;
      }
  .buttontAddEdit, .buttontAddEdit:hover, .buttontAddEdit:focus{
    padding: 6px 54px !important;
    background-color: #3c8dbc;
    font-family:'Montserrat-Regular';
    color: #fff !important;
}
  .editbtns2{
      padding:0px 0px!important;
  }
  .roletbl{
    text-align: center!important;
  }
  .editbtns{
    padding:10px 10px!important;
  }
  .editbtnshvr:hover{
  background:#00b8ff!important;
  border-color:#00b8ff!important;
  color:#333!important;
  }
  .editbtns1{
    padding:5px 5px!important;
  }
  .editbtns2{
    padding:5px 5px!important;
  }
  .addrolesBtnaddroll{
    margin-top: 0px!important;
  }
  .rolelst{
    text-align: center !important;
  
  }
  .CategoriesListUpload {
      font-size: 20px;
      text-align: center;
  }
  .myTable{
    border:1px solid #d3d6de;
  }
  .contentheight{
      min-height:990px!important;
      background:#ecf0f5;
  }
  .editInfo,.editTax{
      height: 29px!important;
      line-height: 16px!important;
  }
  .companysettingbox{
      border:3px solid #bbb!important;
      /*margin-left:10px;*/
  }
  .compSettingTitle,.compSetingInput{
      padding: 0;
  }
  
  /**********comapnay setting tabs************/
  .tablebdy{
      padding: 0;
  }
  .compySettingHr{
      border-top: 1px solid #eee;
      width: 100%;
  }
  .companySettingTabs{
      margin-top: 2em;
      margin-bottom: 2em;
  }
  
  
  /*****DN new css******/


  .sideways>li.active>a, .sideways>li.active>a:focus, .sideways>li>a{
    cursor: pointer;
    background-color: #3c8dbc;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    font-family:'Montserrat-Regular';
    color: #fff ! important;
    font-size: 13px;
    border-radius: 0px !important;


}
  .sideways {
    border-bottom: none;
    margin-top: 10px;
    /*border-right: 1px solid #ddd;*/
  }
  
  .sideways>li {
    float: none;
   margin:0px;
    
  }
  
  .sideways>li.active>a,
  .sideways>li.active>a:hover,
  .sideways>li.active>a:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
    background:#2c1910 !important;
    color: #fff !important;
    border:none;
    border-radius:0px;
    margin:0px;
    margin-bottom: 2%;
    padding: 10px;
  }
  .sideways>li>a:hover {
      /* margin-right: 2px; */
      line-height: 1.42857143;
      border: 1px solid transparent;
      /*background: #275d8e !important;*/
      color: #fff !important;
      /* border-radius: 4px 4px 0 0; */
  }
  .sideways>li.active>a::after{
    content: "";
    position: absolute;
    top: 9.5px;
    right: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    border-left: 10px solid #2c1910;
    display: block;
    width: 0;
  }
  .companyNamechkbx{
      margin-right: 20px!important;
  }
  .tabLeft{

      margin-bottom: 2%;
      color: #333!important;
      border: 1px solid #ccc!important;

  }
  .companySettingMarginTop{
    margin-top: 2%;
  }
  .displayLogo{
    height: 50px;
    width: 50px;
  }
  .companyDisplayForm{
    /*box-shadow: 1px 1px 2px 1px #ccc;*/
    padding: 0;
    border:1px solid #b9b0b0 ;
  }
  .compForm{
    padding:0;
  }
  /*.companyBtns{
    padding: 0;
  }*/
  .csImageWrapper{
    border: 1px solid #ccc;
    margin-top: 0%;
    /*margin-left: 10%;*/
    padding: 4px 0px;
    color: #fff;
   /* border-radius: 100%;*/
  }
  .compinfotp{
    margin-top: 10px;
  }
  .csImageWrapper:hover .displayBlockOne, .csImageWrapper:hover .removeprofPhoto{
    display: inline-block;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
      /*color: #3c8dbc;*/
  
  }
  .outerPhotoWrapper{
    border: 0px solid #eee;
    height: 105px;
    padding: 0px;
    background-size: 100% 100%;
     background-position:center;
    text-align: right;
  }
  .newinputbox{
    border-right: none ! important;
    border-bottom: none !important;
    border-top:none !important;
  }
  .companyDropdown{
    display: none;
    position: absolute;
    right: 10px !important;
    /* background-color: #fff; */
    min-width: 160px;
    box-shadow: 0px 8px 8px 0px #eee;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    margin-top: -4px;
  }
  .compyHeader{
    padding-left: 2em;
}

  .border_box1{
  border-bottom: 1px solid #b9b0b0;
 }

.comp_info_title{
  font-size: 15px;
  color: #0275D8;
  font-family:'Montserrat-Regular';
 }
 .page_title{
  font-family:'Montserrat-SemiBold';
 }
 .compsetbtn{
  padding-right: 28px;
 }
 .margdiv{
  margin-left: 14px;
  padding: 0;
 }
 .addressicon{
  color: #433;
 }
  /*****End DN new css******/
  
  .form-control{
    font-family:'Montserrat-Regular';
    font-size: 13px;
  }
  .NOpadding{
    padding:px;
  }
  .buttontAddEdit, .buttontAddEdit:hover, .buttontAddEdit:focus{
    padding: 6px 54px !important;
    background-color: #3c8dbc;
    font-family:'Montserrat-Regular';
    color: #fff !important;
}
.marginBtmDiv{
  margin-bottom: 20px;
}
/*sandbox UI*/
.checkoutTitle{
  padding-left: 0;
  font-size: 14px;
  font-style: italic;
  margin-top: -20px;
}
.checkOutSettingWrapper{
  margin-top: 3%;
}
.envtSettings{
  box-shadow: 0 0 4px #695d5d;
  text-align: center;
  margin-top: 3%;
  border-radius: 8px;
}
.envLabel{
  text-align: left;
}
.containerC img{
  height: 60px;
}
/*sandbox UI*/
