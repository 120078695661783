.sidenav a.pageHeader{
    height: 50px;
}
.headicon{
    font-size: 1.1vw;
    font-weight: bold;
    padding-top: 20px;
    color: #fff;
    cursor: pointer;
}
.LogoTitle{
  font-family: Montserrat-SemiBold;
  color:transparent;
  margin-top: 14px;
}
.headiconName{
   font-size: 1.1vw;
    font-weight: bold;
    padding-top: 18px;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat-Regular;
}
.hover:hover{
    background-color: #2c1910;
    height: 50px;
}
.img{
    position: absolute;
    top: 15px;
    height: 25px;
}
.labelhead{
  position: absolute;
  top: 9px;
  /* text-align: center; */
  font-size: 0.9vw;
  padding: 2px 2px;
  /* line-height: .9; */
  margin-left: -5px

}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 50px;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  /*font-size: 18px;*/
  color: #333;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
  background-color: #3c8dbc;
  border-left: 3px solid #2c1910;
}

#mySidenav .closebtn:hover {
  background-color: #fff;
  border:0px;
  color: #333;
}
#mySidenav .closebtn i:hover {

  box-shadow: 0px 0px 10px #aaa;
}
#mySidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 14px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  /*.sidenav {padding-top: 15px;}*/
  /*.sidenav a {font-size: 18px;}*/
}

#mysidenav{
  transition: all 0.8s ease 0s;
  z-index: 100;
}
.closeSidebr {
  margin-right: -20px;
}

#headerid{
  position: absolute;
  height: 50px;
  width: 83%;
  right: 0px;
  transition: all 0.8s;
}

.showme{ 
  display: none;
}
.showhim{
  display : block;
}
.logoutAct{
  z-index: 999;
}

.user-footer{
    border: 1px solid #ccc;
    margin-top: 0px;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px #aaa;

}
.profile-btn{
  float: right;
}
.profile-img{
/*  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0px !important;*/
      width: 30px;
    height: 25px;
    border-radius: 50%;
    margin: 0px !important;
}
.arrow-up {
    margin-left: 84%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #1b1c20;
    margin-top: 50px;
}
.logoutDiv{
  margin-bottom: 35px;
}
.btn-Profile{
    background-color: #3c8dbc;
    border-color:#3c8dbc;
    color: #fff;
    border-radius: 3px;
    font-family: 'Montserrat-Regular';
}
.btn-logout{
    background-color: #3c8dbc;
    border-color: #3c8dbc;
    color: #fff;
    border-radius: 3px;
    font-family: 'Montserrat-Regular';
}