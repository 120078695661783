.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
    margin-top: 50px;
}

.marginT{
    margin-top: 40px !important;
    margin-bottom: 30px;

}

.marginT .HeadDistributer{
    font-weight: 700;
    font-size: 30px;
    margin-left: 40px;
}
.viewContent{
	box-shadow: 0px 0px 5px 5px rgba(0,0,0,.075);

}
.mheight{
	height: auto;

}
.setZindex{
	z-index: 1;
	/* display: block; */
}
.setplusZindex{
	z-index: 22;
}

.nopadding{
    padding: 0px 0px;
}
.zeromargin{
    margin: 0px;
}
.setpdf{
    margin-left: -15px;
    margin-top: 10px;
}

.setp{
    padding-left: 15px
}
.labelColor{
    cursor:  pointer;
    /* background-color: #ff0; */
}


.custFaTimes{
    cursor:  pointer;
    /* background-color:  #f00; */
}

.mb30{
    margin-bottom: 20px;
}


