.AllBlogsBox{
	background-color: #f6f6f6;
	border: 1px solid #eaeaea;
	padding: 20px;
}
/*.mtop75{
	margin-top: 75px; 

}*/

.All1blog1{

}
/*.blogTitle{
	margin: 5px 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}*/
.AllblogImgB{
	height: 173px!important;
	width: 100%;
}
.Allblog{
	/*background-color: #888;*/
	
}
.blogPara{
	overflow: hidden;
	height: 80px;
	text-align: justify;
}
.All1blog1:hover .blogTitle{
	color:#25bbf3!important;
}
.All1blog1{
	position: relative;
	height: 390px;
	margin-bottom: 50px;
	background-color: #fff;
	box-shadow: 0px 11px 15px 1px #ccc;
}
.p10{
	padding-left:  15px !important;
	padding-right: 15px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}

.z50{
	position: relative;
	top: -75px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 4%;
 right: -2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.All1blog1:hover .middle {
  opacity: 1;
}

.wclr{
	color: #fff;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}
.rclr{
	color: #f00;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}

.hoverbk{
	    background-color: #111;
    border-radius: 12px;
}
.premiumBlogIndicate{
	  position: absolute;
	  left: -20px;
	  top:14px;
	  padding-left: 18px;
	  padding-right:  20px;
	  transform: rotate(-40deg);
	  background-color:#f5d26e;
	  color: #333;
	  font-size: 16px;

}