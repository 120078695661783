
.material-icons {
	font-size: 20px !important;
}
.selectbox{
    width: 100%;
    height: 34px !important;
    background-color: transparent;
    border: 1px solid #aaa;
    border-radius: 3px;
	margin-bottom: 20px !important;
}