.bulkEmployeeImg img{
    height: 70px;
}
.bulkEmployeeImg{
    padding-left: 0px;
}
.productselection{
    margin-bottom: 20px;
}

.productselection .selectbox{
    margin-bottom: 0px !important;
}

.bulkuploadfile{
    margin-top: 20px;
}

.errorlabel{
    color: #f00;
}

.productchartout{
    margin-top: 40px;
}
.productchartcontent{
    margin-bottom: 40px;
}
.bulkEmployeeContent .table-responsive {
    max-height: 580px;
    overflow-y: scroll;
}

/*tbody {
    display:block;
    max-height:560px;
    overflow-y:scroll;
}
tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead{
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
/*}*/

.nav-pills.chartpills>li {
    float: left;
    border-top: 1px solid #dde0e4;
    border-bottom: 1px solid #dde0e4;
    padding-right: 0px;
    margin-left: 0px !important;
}   

.nav-pills.chartpills>li:last-child {
    border-right: 1px solid #dde0e4; 
}

.nav-pills.chartpills > li > a {
    border-radius: 0;
    color: #444;
    border-left: 1px solid #dde0e4;
    padding: 7px 10px;
}    

.nav-pills.chartpills > li.active > a, .nav-pills.chartpills > li > a.active {
    margin-bottom: 0px !important;
    background-color: #fff !important;
    border: 1px solid #3c8dbc !important;
    color: #3c8dbc !important;
    padding: 7px 10px !important;
}   


.chartdiv{
	padding-top: 30px;
}

.hrline{
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.nav-pills.chartpills{
    float: right;
}

.submitBtn{
    background: #3c8dbc;
    color: #FFF;
    opacity: 1;
    padding: 9px 15px;
    font-size: 14px;
}
.submitBtnGo{
    background: #3c8dbc;
    color: #FFF;
    opacity: 1 !important;
    padding: 9px 15px;
    font-size: 14px;
}

.cagrvalue{
    font-weight: 600;
}