
.nopadding{
	padding: 0px;
}
.mt20{
	margin-top: 20px;
}
.noMl{
	margin-left: 0px !important;
}
.setMargin{
	margin-top: 10px;
}
.mt10{
	margin-top: 10px;
	} 
.mb10{
	margin-bottom: 10px;
}
.mt15{
	margin-top: 15px;
}
.mt25{
	margin-top: 25px;
}
.bkcolor{
	background-color: yellow;
}
.setheight{
	height: 55px;
}
.setArea{
	height: 400px;
	margin: 10px;
	margin-left: 0px;
	/* background-color: #ff0; */
}
.setBackground{
	height: 350px;
	/* background-color: #000; */
	margin: 10px;

}
.img-circle{
	height: 220px;
}

.fontWeight{
	font-weight: 400;
}
.pl{
	padding-left: 0px;
}

.panel-heading{
	text-align: center;
}
.panel-heading h4 b{
	margin-left: 70px;
}
.mt19{
	margin-top: 19px;
}
.pt{
	padding-top: 20px;
}
.pl100{
	padding-left: 180px;
}
.setBackgroundAccountdetails {
    height: 250px;
    /* background-color: #000; */
   
}