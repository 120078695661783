.NotificationContent {
  min-height: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 15px;
}
.CreateTempModal{
    margin-top: 8px;
}
.CreateTempModalDelete{
    margin-top: -30px !important;
}
.inputValid{
    height: 30px;
}

.inputValid:valid {
    border: 1px solid #ccc;
}
.inputrow{
    margin-bottom: 1%;
}
.inputrow1{
    margin-bottom: 4%;
    margin-top: 4%;
}

.NOpadding{
    padding: 0px !important;
}

.notifTab{
    text-align: center;
    font-family: 'MYRIADPROREGULAR';
    letter-spacing: 1px;
}
.notifWrapper{
    height: auto;
    border-radius: 3px;
    margin-left: 0 !important;
    background: #fff !important;
    margin-top: 5%;
    margin-bottom: 7%;
}
.hideDiv{
    visibility: hidden;
}
.notifWrapper .title{
    text-align: center !important;
    font-size: 25px;
    color: #333;
    margin: 2% 0% !important;
}
.notificationTitle{
    text-align: center;
    color: #DA2128;
    font-weight: bold;
    margin-bottom: 20px;
}

.text-message-content-wrapper{
    height: auto;
    padding: 1%;
    background: #AED6F1;
}
.text-message-innerWrapper{
    height: auto;
    padding: 2%;
    background: #ffffff;
}
.toLabeldiv{
    height: 54px;
    text-align: center;
    font-size: 13px;
    border: 1px solid #aaa;
    border-radius: 4px;
}
.tolabel{
    text-align: center;
    margin-top: 18px;
    font-weight: bold;
    font-size: 13px;
    font-family: 'MYRIADPROREGULAR';
}
.fixlength{
    color: #1B4F72;
    font-family: 'MYRIADPROREGULAR';
    margin-top: 5px;
}
#textarea{
    border-radius: 0px;
    border-color: #aaa;
    border-radius: 4px;
}
#textarea_feedback{
    color: #1B4F72;
    font-family: 'MYRIADPROREGULAR';
    margin-top: -19px;
}
.senderTxtarea{
    border-radius: 0px;
    border-color: #aaa;
    border-radius: 4px; 
}
.sendtxtmsgbtn{
    border-radius: 0px;
}
.create-email-template-wrapper{
    height: auto;
    padding: 1.3%;
    /*border: 5px solid #3c8dbc;*/
    margin-bottom: 5%;
}
.sidertemplatebar{
    height: auto;
    min-height: 299px;
    /*border-right: 5px solid #2189d1;*/
    padding-top: 4px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: visible;
    /*overflow-y: auto;   */
    background: #f1f1f1;

}
.editPencil,.deleteEM{
    margin-left: 12px;
    margin-right: 5px;
}
.bellIcon{
    margin-right: 10px;
    margin-left: 4px;
}
.saveTemplateWrapper{
    height: auto;
    min-height: 200px;
    background: #fff;
    /*padding: 1.3%;*/
    /*border: 1px solid #f1f1f1  ;*/
}
.ViewTemplateWrapper1{
    height: 600px;
    background: rgba(179,220,237,1);
    padding: 1.3%;
    border: 7px solid #3c8dbc  ;
    display: none;
}

.ViewNotifTemplateWrapper1{
    height: 600px;
    background: rgba(179,220,237,1);
    padding: 1.3%;
    border: 7px solid #3c8dbc  ;
    display: none; 
}

.notificationTemplateWrapper{
    display: none;
    height: 600px;
    background: #fff;
    padding: 1.3%;
    border: 7px solid #3c8dbc;
}

.userDefinedTemplateWrapper{
    display: none;
}

.newTemplate{
    background: #3c8dbc;
    height: 50px;
    padding: 5%;
    text-align: center;
    width: 93%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 2%;
}
.notificationTemplate{
    background: #3c8dbc;
    height: 50px;
    padding: 5%;
    text-align: center;
    width: 93%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 2%;
}
.smsTemplate{
    background: #3c8dbc;
    height: 50px;
    padding: 5%;
    text-align: center;
    width: 93%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 2%;
}

.tempCategory {
    height: 65px;
    list-style: none;
    text-align: left;
    text-align: center;
    cursor: pointer;
    margin-bottom: -1%;
    margin-left: -25px;
    font-size: 12px;
    border-bottom: 1px solid #999;
    width: 104%;
    color: #666;
    padding-top: 1.4em;
}

.tempCategoryName{
    padding: 5%;
    height: 50px;
    cursor: pointer;
}

.tempCategory a{
    color: #fff !important;
}

.tempactive {
    width: 104% !important;
    box-shadow: 0px 8px 40px  #3c8dbc;
    color: #666;
    font-size: 13px;
    z-index: 2 !important;
    position: relative;
}
.tempactive .iconCss {
    border-radius: 18px;
    background: #3c8dbc !important;
    padding: 0.7em;
    color: #fff;
}
.emailSpan{
    color:#333;
    margin-top: .7em;
    font-size: 12px;
    font-family:'Montserrat-Regular';
    letter-spacing: 1px;
}

#emailTemplates{
    margin-top: 2em;
    margin-bottom: 2em;
    height: auto;
    min-height: 300px;
    border:1px solid #f1f1f1;
   border-top: 1px solid #f1f1f1;
}

#notificationTemplates{
    margin-top: 2em;
    margin-bottom: 2em;
    height: auto;
    min-height: 300px;
    border:1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
}
.templateTitle{
    font-family: 'Myriad-Pro-Semibold';
    letter-spacing: 1px;
}


#smsTemplates{
   margin-top: 2em;
    margin-bottom: 2em;
    height: auto;
    min-height: 300px;
    border:1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
}
.savetemp b{
    margin-left:3px;
     font-size: 13px;
}
.input-category{
    width: 100%;
    height: 35px;
    border-radius: 2px;
    border :1px solid #aaa;
    padding: 4px;
}
.label-category{
    color: #111;
    font-size: 13px;
    font-weight: 600;
    padding: 0;
}
.headercreatetemplate{
    font-size: 15px;
    color: #ffffff;
}
.savetemp{
    margin-top: 10px;
}
.category-check{
  height: 16px;
  width: 16px;  
}

.labelWrapper{
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 10px #999;
    padding: 2%;

}
.labelcontactsHeader{
    text-align: center;
    font-size: 15px;
    font-family: 'MYRIADPROREGULAR';
}
.labelContainer{
    margin-top: 10px;
}
.filesymbol{

}
.filesymbol li{
  list-style: none;
  float: left;
  margin-left: 10px;
  cursor: pointer;
  margin-top: -21px;
}
.labelContainer{
    height: auto;
    padding: 2%;
    border: 1px solid #aaa;
    text-align: center;
    margin-top: 0px;
}
.createLabelContainer{
    padding: 9px;
}
.createlabel-label{
    font-size: 14px;
    font-family: 'MYRIADPROREGULAR';
    color: #616A6B  ;
}
.createlabel-label1{
     font-size: 13px;
    font-weight: normal;
    font-family: 'MYRIADPROREGULAR';
    color: #616A6B;

}
.email-template-wrapper{
    height: auto;
    padding: 0px !important;
    border: 5px solid #ccc;
}
.sidebarAllTemplate{
    height: 561px;
    background: #ffffff;
    border-right: 7px solid #ccc;
    padding: 1%;
}
.emailbodyWrapper{
    height: 561px;
    background: #fff;
    padding: 2%;
    border: 7px solid #2574A9  ;
}
.toemaildiv{
    height: 54px;
    text-align: center;
    border-radius: 4px;
}
.senderTxtarea1{
    border-radius: 3px;
    border-color: #aaa;
}
.senderccInput{
    width: 100%;
    border-radius: 3px;
    border: 1px solid #aaa;
    height: 30px;
    padding: 4px;
}
.toccdiv{
    height: 30px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
}

.alllabel{
    text-align: center;
    margin-top: 7px;
    font-weight: bold;
    font-size: 13px;
    font-family: 'MYRIADPROREGULAR';
}
.sendemailbtn{
    border-radius: 0px;
    margin-top: 10px;
}
.templateLibrary{
    /*padding:0px;*/

}
.templateLibrary ul{
    padding: 0px;
}
.templateLibrary ul li{
    list-style: none;
    height: 40px;
    background: linear-gradient(to bottom,#e1e5f1 ,#bfc2cb);
    width : 93%;
    text-align: center;
    color: #000;
}
.textAreaBox{
    display: block;
    margin-top: 10px;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    word-break: break-word;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.tempCategory{
    background: #839ccc !important;
    /* height: 40px; */
    /* padding: 3%; */
    text-align: left;
    /*width: 100%;*/
    /* color: #fff; */
    /* font-weight: bold; */
    cursor: pointer;
    margin-bottom: -1%;
    margin-left: -25px;
    font-size: 12px; 
    border-bottom: 1px solid #999;
    width: 104%;
    padding-top: 1em;
    /* font-weight: bold; */
}
.templateLibrary ul li a{
    text-decoration: none;
    color: #444 !important;
    font-size: 13px;
    font-family: 'MYRIADPROREGULAR';
}
.templateLibrary ul li:hover{
    background: linear-gradient(to bottom, #bfc2cb, #dadde7, #bfc2cb);
}
.templateLibraryHeader{
    padding: 2%;
    /*margin-top: 10px;*/
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    margin-left: -3em;
}

.library-submenu li{
    /*height: 40px;
    width: 100%;
    background : linear-gradient(to bottom, #707B7C,  #AEB6BF  , #707B7C);*/
    background: rgba(143,181,214,1);
    height: 50px;
    padding: 2%;
    text-align: center;
    width: 93%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 2%;

}

.editTemp a{
    color: #000;
}
.editTemp{
    width: 100%;
    padding: 0;
    border-radius: 0px;
}
.checkMail span.glyphicon {               
    opacity: 0;             
}
.checkMail.active span.glyphicon {                
    opacity: 1;             
}
.checkMail{
    border: 1px solid #eee;
}

.messageContent{
    height: 280px;
    background: #fff;
    overflow-y: scroll;
}

.statusColor{
    background: #00c0ef;
}

.notifmenu{
    font-size: 12px;
}
.notifFooter{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 12px;
    /*background-color: #fff;*/
    padding: 7px 10px !important;
    border-bottom: 1px solid #eeeeee;
    color: #fff !important;
    text-align: center;
}
.notifFooter a{
    color: #fff !important;
}
.notifSpan{
    padding: 0px !important;
}
.notifSpan li{
    list-style: none;
    font-size: 12px
}
.iconCss {
    border-radius: 18px;
    background: #3c8dbc;
    padding: 0.7em;
    color: #fff;
    margin-top: 8px;
}
.notifSpan{
    padding: 0px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.notifContent{
    /*color: #fff;*/
    padding: 1%;
    display: block;
    white-space: nowrap;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
}

.innerNotifLi{
    margin-bottom: 1%;
    /*border-bottom: 1px solid #f4f4f4;*/
}

.notif1{
    text-align: left;
    font-size: 12px !important;
}

.notif2{
    font-size: 10px !important;
}

.notiful{
    padding: 0px;
    top: 100%;
    left: 0;
    float: left;
    min-height: 335px;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    /*overflow-y: scroll;*/
}

.notiful li{
    padding: 0px;
    color: #fff;
   border-bottom: 1px solid #f4f4f4; 
}

.notiful li:hover{
    background-color: #777;
    color: #fff;
}
.notiful li a{
    color: #fff;
}

.submitConfig{
    padding: 0;
    margin: 3px 0 0;
    width: 81%;
    background: #f89b1c;
    color: #fff;
    font-size: 15px;
    height: 38px;
    border: 0;
    border-radius: 0;
}

.notificationFooter a{
    background: #fff;
    text-align: center;
}

.notificationheader{
    background: #fff;
    text-align: center;
}

.notifbody{
    font-size: 12px;
}

.notifdate{
    font-size: 10px;
}

.notifvalMenu{
    max-height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;
}

.notif-dropdown{
    width: 280px;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
    border: 0px !important;
}

.tabContentStyle{
    margin-top: 10%;
}

.defaultMsg{
    color: #ccc;
    text-align: center;
    margin-top: 15%;
}

.defaultMsg i{
    font-size: 24px;
}
.updatebtn{
    display: none;
}

.showTemplate{
    height: 242px;
    /*overflow-y: scroll;*/
}

.noBorderBox{
    border: 0px !important;
}
.contentBox{
    /*border: 1px solid #ccc;*/
    padding: 2%;
}
.box.box-primary{
    border-top-color: #2189d1 !important;
}

.box{
    padding-bottom: 2%;
}

.notifyHeader{
    padding-top: 15px;
    padding-right: 26px;
    padding-left: 26px;
    padding-bottom: 0px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 1em;
    
}
.rowPadding{
    margin-bottom: 1em;
}
.saveTempBtn{
    margin-bottom: 19px;
}
.modalContent{
    /*border-radius: 10px;*/
    /*border-top: 3px solid #2189d1;*/
    margin-bottom: 15px;
}
.close{
    /*margin-top: -35px !important; */
    /*font-size: 2.5em;*/
}
.notifTabs{
    text-align: center;
    margin-bottom: 2em;
    margin-top: 2em;
}
.dropbtn {
    color: #666;
    /*padding: 16px;*/
    font-size: 16px;
    border: none;
    cursor: pointer;

}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: -8px;
    min-width: 160px;
    box-shadow: 0px 8px 8px 0px #eee;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    /*margin-top: -4px;*/
}

.dropdown-content .deleteNotif:hover {background-color: #f1f1f1;
    cursor:pointer;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #fff; color:#666;}

.dropdown-content .deleteNotif:hover { 
    background-color: #f1f1f1;
    cursor: pointer;
    color: #666666;
    padding: 7px 7px;
    text-decoration: none;
    display: block;
    border-radius: 3px;}
.dropdown:hover .dropdown-content {display: block; background: #fff;}
.dropdown:hover .dropbtn {background-color: #fff; color:#666;}

.deleteNotif{
    padding: .5em;
    border-radius: 15px;
}
.dropdown-content:after {
    position: absolute;
    top: -11px;
    right: 14px;
    display: inline-block;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f2f2f2;
    border-left: 10px solid transparent;
    content: '';
}
.addNotifybutton{
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
    -webkit-font-smoothing: antialiased;
    font-family:'Myriad-Pro-Semibold';
    letter-spacing: 1px;
    -webkit-align-items: center;
    background-color: #fff;
    -webkit-border-radius: 24px;
    border-radius: 24px;
    color: #3c4043;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 36px;
}
.plusIconNt{
    margin-right: 10px;
    margin-left: 3px;
}
.modalCloseBtn{
    margin-top: -45px !important;
}
.deleteModalContent{
    border-radius: 10px;
    border-top: 3px solid #2189d1;
}
.tab-content {
  padding: 10px 15px;
}
.box{
    height: 650px;
}
.nav-pillss>li>a {
    color: #ffffff;
    white-space: nowrap;
    box-shadow: 2px 2px 5px 0 #3c8dbc;
    background: #3c8dbc;
    border-radius: 4px;
    font-family:'Montserrat-Regular';
    font-size: 14px;
}
.nav-pillss>li>a:hover{
    color: #ffffff;
    white-space: nowrap;
    box-shadow: 4px 4px 10px 0 #3c8dbc;
    background: #3c8dbc;
    border-radius: 4px;
    font-family:'Montserrat-Regular';
    font-size: 14px;
}
.dropbtn {
    color: #666;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.defaultNotification {
    color: #ccc;
    text-align: center;
    margin-top: 15%;
}
.defaultSMS {
    color: #ccc;
    text-align: center;
    margin-top: 15%;
}
.astrick{
    color: #ea0202;
}
.error{
    color: #ea0202;
}


.blackFont{
    color: #333;
  }
  .redFont{
    color: #ea0202;
  }
  .NOpadding-left{
      padding-left: 0px;
  }
  .NOpadding-right{
      padding-right: 0px;
  }
  .adminModal-header {
   /*   border-radius: 9px 9px 0 0;
      background: #2189d1;*/
      color: #333;
    /*font-size: 13px !important;*/
      font-family:'Montserrat-Regular';
      /*border: none;*/
  }
  .adminModal-body {
    margin-top: 25px;
    /*margin-bottom: 50px;*/
    padding: 0;
  }
  .adminModal-footer {
      border-radius: 0 0 9px 9px;
      background: #fff;
      padding-top: 15px!important;
      padding-bottom: 0!important;
  }
  .adminCancel-btn, .adminFinish-btn {
      margin-bottom: 15px;
      box-shadow: 2px 2px 9px -2px #2189d1;
  }
  .btnUpdate{
      color: #fff;
      background: #2189d1;
      margin-bottom: 15px;
      box-shadow: 2px 2px 9px -2px #2189d1;
  }
  .btnSubmit{
      color: #fff;
      background: #2189d1;
      margin-bottom: 15px;
      /*box-shadow: 2px 2px 9px -2px #2189d1; */
  }
  .adminCloseCircleDiv {
    display: inline-grid;
    box-shadow: 3px 4px 7px -3px #666!important;
    /* height: 30px; */
    width: 30px;
    background: #fff;
    /* border-radius: 100%; */
    margin-top: -31px;
  }
  .adminCloseButton {
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      -webkit-appearance: none;
      padding: 0;
      cursor: pointer;
      background: transparent;
      /*border: 0;*/
      font-size: 24px!important;
      opacity: 1!important;
      float: left;
  }
  .textAlignCenter {
      text-align: center!important;
  }
  .textAlignLeft {
      text-align: left!important;
  }
  .textAlignRight {
      text-align: right!important;
  }
  .addexamform {
    background: #3c8dbc;
    cursor: pointer;
    font-size: 14px;
    padding: 3px;
    margin-top: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
    text-align: center;
    color: #fff;
}
.notification_title{
    margin-left: 10px;
}
.notificationsbnt{
    margin-top: 20px;
}
.plus_sign{
  margin: 5px;
  margin-left: 5px;
  /*color: #0275D8;
  background-color: #ffffff;*/
}
.templateDiv{
    margin-top: -20px;
}
.h2Title{
    font-family:'Montserrat-SemiBold';
}
/*.nav>li>a {
    position: relative;
    display: block;
    padding: 4px 15px;
    color: #ffffff ! important;
}*/
.nav-pillss>li.active>a, .nav-pillss>li.active>a:focus, .nav-pillss>li.active>a:hover{
    color: #fff !important;
    background-color: #2c1910 !important;
}


